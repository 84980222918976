import { createRouter, createWebHistory } from 'vue-router';

// Importa tus componentes
//import HelloWorld from './components/HelloWorld.vue';
import HomeMostrador from './components/HomeMostrador.vue';
import HelloWorldVue from './components/HelloWorld.vue';


// Define tus rutas
const routes = [
  {
    path: "/",
    component: HelloWorldVue,
  },
  {
    path: "/shipments/:shipment_id/step/:step",
    component: HomeMostrador,
  },
]

// Crea una instancia de Vue Router
const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;