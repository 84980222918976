<template>
    <div class="block">
        <h1 class="title has-text-centered">¿Qué te interesa saber de tu envío?</h1>
        <div class="columns">
            <div class="column"><div class="box is-clickable" :class="{ 'is-active': rates_based_on == 'Tiempos de entrega' }" @click="update('Tiempos de entrega')"><h2 class="subtitle  has-text-centered">Tiempos de entrega</h2></div></div>
            <div class="column"><div class="box is-clickable"  :class="{ 'is-active': rates_based_on == 'Precios' }" @click="update('Precios')"><h2 class="subtitle  has-text-centered">Precios</h2></div></div>
        </div>
    </div>

</template>
    
<script>

import { mapState, mapActions } from 'vuex';



export default {
    name: 'RatesBasedOn',
    computed: {
        ...mapState(['current_step','rates_based_on'])
    },
    methods:{
        ...mapActions(['updateShipment']),
        update(rates_based_on){
            
            this.updateShipment({
                'rates_based_on':rates_based_on
            })
        }
    }

}

</script>

<style scoped>

.box.is-active{
    border:solid 2px orange;
}

</style>