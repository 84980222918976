<template>
  <div class="container">
    <p class="mb-3">Shipment ID: {{ id }}</p>
    <p class="mb-3">User ID: {{ user_id }}</p>
    <div :class="{ 'is-hidden': currentStep !== 1 }">
      <div class="field">
        <label class="label">¿Cuál es el destino de tu envío?</label>
        <div class="select">
          <select v-model="destination_type">
            <option value="">Seleccione una opcion</option>
            <option value="Local">Local</option>
            <option value="Nacional">Nacional</option>
            <option value="Internacional">Internacional</option>
          </select>
        </div>
      </div>
      <button @click="nextStep" class="button is-primary">Siguiente</button>
    </div>
    <div :class="{ 'is-hidden': currentStep !== 2 }">
      <div class="field">
        <label class="label">¿Qué te interesa saber de tu envío?</label>
        <div class="select">
          <select  v-model="rates_based_on">
            <option value="">Seleccione una opcion</option>
            <option value="Tiempos de entrega">Tiempos de entrega</option> 
            <option value="Precios">Precios</option>
          </select>
        </div>
      </div>
      <button @click="prevStep" class="button is-danger">Anterior</button>
      <button @click="nextStep" class="button is-primary">Siguiente</button>
    </div>
    <div :class="{ 'is-hidden': currentStep !== 3 }">
      <div class="field">
        <label class="label">¿Cuándo necesitas que llegue?</label>
        <div class="select" >
          <select v-model="needed_delivery_time">
            <option value="">Seleccione una opcion</option>
            <option value="Hoy">Hoy</option>
            <option value="Mañana">Mañana</option>
            <option value="Esta semana">Esta semana</option>
            <option value="Sin prisa">Sin prisa</option>
          </select>
        </div>
      </div>
      <button @click="prevStep" class="button is-danger">Anterior</button>
      <button @click="nextStep" class="button is-primary">Siguiente</button>
    </div>
    <div :class="{ 'is-hidden': currentStep !== 4 }">
      <div class="field">
        <label class="label">¿Qué quieres enviar?</label>
        <div class="select">
          <select v-model="shipment_type">
            <option value="">Seleccione una opcion</option>
            <option value="Document">Papeles</option>
            <option value="Package">Artículos</option>
          </select>
        </div>
      </div>
      <button @click="prevStep" class="button is-danger">Anterior</button>
      <button @click="nextStep" class="button is-primary">Siguiente</button>
    </div>
    <div :class="{ 'is-hidden': currentStep !== 5 }">
      <div class="field">
        <label class="label">Origen</label>
        <div class="control" v-if="created">
          <simple-typeahead
            id="origin"
            placeholder="Introduce un Código Postal"
            :items="suggestionsOrigin"
            :itemProjection="originItemProjection"
            @selectItem="setOriginAddress"
            @onInput="onInputOriginChange"           
            :defaultItem="origin_direction"

            >
          </simple-typeahead>
        
          
        </div>
      </div>
      <div class="field">
        <label class="label">Destino</label>
        <div class="control" v-if="created">
          <simple-typeahead
            id="origin"
            placeholder="Introduce un Código Postal"
            :items="suggestionsDestination"
            :itemProjection="itemProjectionFunction"
          
            @selectItem="setDestinationAddress"
            @onInput="onInputDestinationChange"
            :defaultItem="destination_direction"
            >
          </simple-typeahead>
        </div>
      </div>
      <button @click="prevStep" class="button is-danger">Anterior</button>
      <button @click="nextStep" class="button is-primary">Siguiente</button>
    </div>
    <div :class="{ 'is-hidden': currentStep !== 6 }">
      <div class="field">
        <label class="label">Tus artículos se enviarán en:</label>
        <div class="select">
          <select v-model="package_type">
            <option value="">Seleccione una opcion</option>
            <option value="Sobre">Sobre</option>
            <option value="Caja">Caja</option>
          </select>
        </div>
      </div>
      <div class="field">
        <label class="label">largo</label>
        <div class="control">
          <input class="input" type="text" placeholder="Text input" v-model="length">
        </div>
      </div>
      <div class="field">
        <label class="label">ancho</label>
        <div class="control">
          <input class="input" type="text" placeholder="Text input" v-model="width">
        </div>
      </div>
      <div class="field">
        <label class="label">alto</label>
        <div class="control">
          <input class="input" type="text" placeholder="Text input" v-model="height">
        </div>
      </div>
      <div class="field">
        <label class="label">peso</label>
        <div class="control">
          <input class="input" type="text" placeholder="Text input" v-model="weight">
        </div>
      </div>
      <button @click="prevStep" class="button is-danger">Anterior</button>
      <button @click="nextStep" class="button is-primary">Siguiente</button>
    </div>
    <div :class="{ 'is-hidden': currentStep !== 7 }">
      <label class="checkbox">
        <input type="checkbox" v-model="insured">
        Quiero asegurar mi envío.
      </label>
      <div class="field">
        <label class="label">Monto asegurado MXN</label>
        <div class="control">
          <input class="input" type="text" placeholder="Text input" v-model="insured_value">
        </div>
      </div>
      <label class="checkbox">
        <input type="checkbox" v-model="pickup">
        Solicitar recolección
      </label>
      <div class="field">
        <label class="label">Solicitar recolección</label>
        <div class="control">
          <input class="input" type="date" placeholder="Text input"  v-model="pickup_date">
        </div>
      </div>
      <button @click="prevStep" class="button is-danger">Anterior</button>
      <button @click="nextStep" class="button is-primary">Siguiente</button>
    </div>
    <div :class="{ 'is-hidden': currentStep !== 8 }">
      <h1 class="title">Completa tus datos</h1>
      <h2 class="subtitle">Usuario</h2>
      <div class="field">
        <label class="label">Nombre(s)</label>
        <div class="control">
          <input class="input" type="text" placeholder="Text input" v-model="user_first_name">
        </div>
      </div>
      <div class="field">
        <label class="label">Apellido(s)</label>
        <div class="control">
          <input class="input" type="text" placeholder="Text input" v-model="user_last_name">
        </div>
      </div>
      <div class="field">
        <label class="label">Teléfono</label>
        <div class="control">
          <input class="input" type="phone" placeholder="Text input" v-model="user_phone">
        </div>
      </div>
      <div class="field">
        <label class="label">Email</label>
        <div class="control">
          <input class="input" type="email" placeholder="Text input" v-model="user_email">
        </div>
      </div>
      <h2 class="subtitle">Origen</h2>
      <div class="field">
        <label class="label">Nombre(s) y Apellido(s)*</label>
        <div class="control">
          <input class="input" type="text" placeholder="Text input" v-model="origin_full_name">
        </div>
      </div>
      <div class="field">
        <label class="label">Correo electrónico</label>
        <div class="control">
          <input class="input" type="text" placeholder="Text input" v-model="origin_email">
        </div>
      </div>
      <div class="field">
        <label class="label">Teléfono*</label>
        <div class="control">
          <input class="input" type="text" placeholder="Text input" v-model="origin_phone">
        </div>
      </div>
      <div class="field">
        <label class="label">Calle y Número*</label>
        <div class="control">
          <input class="input" type="text" placeholder="Text input" v-model="origin_addres_1">
        </div>
      </div>
      <div class="field">
        <label class="label">Colonia</label>
        <div class="control">
          <input class="input" type="text" placeholder="Text input" v-model="origin_neighborhood">
        </div>
      </div>
      <div class="field">
        <label class="label">Código Postal</label>
        <div class="control">
          <input class="input" type="text" placeholder="Text input" v-model="origin_postal_code">
        </div>
      </div>
      <div class="field">
        <label class="label">Ciudad</label>
        <div class="control">
          <input class="input" type="text" placeholder="Text input" v-model="origin_city">
        </div>
      </div>
      <div class="field">
        <label class="label">Municipio</label>
        <div class="control">
          <input class="input" type="text" placeholder="Text input" v-model="origin_municipality">
        </div>
      </div>
      <div class="field">
        <label class="label">Distrito</label>
        <div class="control">
          <input class="input" type="text" placeholder="Text input" v-model="origin_district">
        </div>
      </div>
      <div class="field">
        <label class="label">Estado</label>
        <div class="control">
          <input class="input" type="text" placeholder="Text input" v-model="origin_state">
        </div>
      </div>

      <div class="field">
        <label class="label">Referencias*</label>
        <div class="control">
          <input class="input" type="text" placeholder="Text input" v-model="origin_addres_2">
        </div>
      </div>
      <h2 class="subtitle">Destino</h2>
      <div class="field">
        <label class="label">Nombre(s) y Apellido(s)*</label>
        <div class="control">
          <input class="input" type="text" placeholder="Text input" v-model="destination_full_name">
        </div>
      </div>
      <div class="field">
        <label class="label">Correo electrónico</label>
        <div class="control">
          <input class="input" type="text" placeholder="Text input" v-model="destination_email">
        </div>
      </div>
      <div class="field">
        <label class="label">Teléfono*</label>
        <div class="control">
          <input class="input" type="text" placeholder="Text input" v-model="destination_phone">
        </div>
      </div>
      <div class="field">
        <label class="label">Calle y Número*</label>
        <div class="control">
          <input class="input" type="text" placeholder="Text input" v-model="destination_addres_1">
        </div>
      </div>
      <div class="field">
        <label class="label">Colonia</label>
        <div class="control">
          <input class="input" type="text" placeholder="Text input" v-model="destination_neighborhood">
        </div>
      </div>
      <div class="field">
        <label class="label">Código Postal</label>
        <div class="control">
          <input class="input" type="text" placeholder="Text input" v-model="destination_postal_code">
        </div>
      </div>
      <div class="field">
        <label class="label">Ciudad</label>
        <div class="control">
          <input class="input" type="text" placeholder="Text input" v-model="destination_city">
        </div>
      </div>
      <div class="field">
        <label class="label">Municipio</label>
        <div class="control">
          <input class="input" type="text" placeholder="Text input" v-model="destination_municipality">
        </div>
      </div>
      <div class="field">
        <label class="label">Distrito</label>
        <div class="control">
          <input class="input" type="text" placeholder="Text input" v-model="destination_district">
        </div>
      </div>
      <div class="field">
        <label class="label">Estado</label>
        <div class="control">
          <input class="input" type="text" placeholder="Text input" v-model="destination_state">
        </div>
      </div>
      <div class="field">
        <label class="label">Referencias*</label>
        <div class="control">
          <input class="input" type="text" placeholder="Text input" v-model="destination_addres_2">
        </div>
      </div>
      <button @click="prevStep" class="button is-danger">Anterior</button>
      <button @click="nextStep2" class="button is-primary">Siguiente</button>
    </div>
    <div :class="{ 'is-hidden': currentStep !== 9 }">
      <h1 class="title">Ingresa el contenido</h1>
      <div class="filed">
        <label class="label">Tipo de producto</label>
        <div class="control" v-if="created">
          <simple-typeahead
            id="origin"
            placeholder="Catálogo de productos"
            :items="suggestionsClaveProd"
            :itemProjection="itemProjectionClaveProd"
            @selectItem="setClaveProd"
           
          >
         </simple-typeahead>
         </div>

      </div>
      <div class="filed">
        <label class="label">Tipo de embalaje</label>
        <div class="control" v-if="created">
        <simple-typeahead
            id="origin"
            placeholder="Catálogo de embalajes"
            :items="suggestionsClaveUnidadPeso"
            :itemProjection="itemProjectionClaveProd"
            @selectItem="setClaveUnidadPeso"
          
          >
        </simple-typeahead>
        </div>
      </div>
      <div class="field">
        <label class="label">Contenido</label>
        <div class="control">
          <input class="input" type="text" placeholder="Text input" v-model="content"> 
        </div>
      </div>
      <div class="field">
        <label class="label">Valor estimado</label>
        <div class="control">
          <input class="input" type="text" placeholder="Text input" v-model="value">
        </div>
      </div>
        
      <button @click="prevStep" class="button is-danger">Anterior</button>
      <button @click="nextStep" class="button is-primary">Siguiente</button>
    </div>
    <div :class="{ 'is-hidden': currentStep !== 10 }">
      <button @click="cotizar" class="button is-warning">cotizar</button>
      <div class="card" v-for="(rate, index) in rates" :key="index">
        <a href="javascript:;" @click="generateDynamicHref(rate)" >
        <div class="card-content">
          <div class="media">
            <div class="media-left">
              <figure class="image is-48x48">
                <img src="https://bulma.io/images/placeholders/96x96.png" alt="Placeholder image">
              </figure>
            </div>
            <div class="media-content">
              <p class="title is-4">{{ rate.carrier.name  }} {{ rate.service_name  }}</p>
              <p class="subtitle is-6">{{ rate.total_amount  }}</p>
            </div>
          </div>

        </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>

import axios from 'axios';

import SimpleTypeahead from 'vue3-simple-typeahead'
import 'vue3-simple-typeahead/dist/vue3-simple-typeahead.css'

const backendUrl = process.env.VUE_APP_BACKEND_URL;

export default {
  name: 'HelloWorld',
  components: {
    SimpleTypeahead
  },
   async created(){

  
     
    
    if(typeof this.$route.query.paso != 'undefined'){
      this.currentStep =  parseInt(this.$route.query.paso) 
    }

    if(typeof this.$route.query.shipment_id != 'undefined'){

      const shipment_id = this.$route.query.shipment_id

      const url = backendUrl+'/shipments/'+shipment_id;

      const response = await axios.get(url);
      
      if(Object.entries(response.data).length > 0){
          for (let k in response.data) {
            if(response.data[k] != null){
              this[k] = response.data[k]
            }
          }
      }
      /*
      if(this.product_category != null && typeof this.product_category == 'string'){

        var match_product_category = this.product_category.match(/^(.*?)\s*\(\s*(\d+)\s*\)\s*$/);

        if (match_product_category) {
          // match[1] contiene el texto antes del paréntesis y match[2] contiene el valor.
          this.product_category = {
            value: match_product_category[1].trim(),
            key: parseInt(match_product_category[2], 10) // Convertimos el valor en un número entero.
          };

          
     
        }
     
      }

      if(this.packaging_category != null && typeof this.packaging_category == 'string'){

        var match = this.packaging_category.match(/^(.*?)\s*\(\s*([^)]+)\s*\)\s*$/);

        if (match) {
          // match[1] contiene el texto antes del paréntesis y match[2] contiene el valor.
          this.packaging_category = {
            value: match[1].trim(),
            key: match[2].trim() // Convertimos el valor en un número entero.
          };
          
        }

      }
      */
    
      if(this.origin_direction.value != ""){
        this.suggestionsOrigin.push(response.data.origin_direction)
      }else{
        this.origin_direction.value = ""
      }

      if(this.destination_direction.value != ""){
        this.suggestionsDestination.push(response.data.destination_direction)
      }else{
        this.destination_direction.value = ""
      }

      if(response.data.origin_direction != null){
        this.origin_full_name = ( response.data.origin_direction.full_name != null ) ? response.data.origin_direction.full_name : ""
        this.origin_email = ( response.data.origin_direction.email != null ) ? response.data.origin_direction.email : ""
        this.origin_phone = ( response.data.origin_direction.phone != null ) ? response.data.origin_direction.phone : ""
        this.origin_addres_1 = ( response.data.origin_direction.direction_1 != null ) ? response.data.origin_direction.direction_1 : ""
        this.origin_neighborhood = ( response.data.origin_direction.neighborhood != null ) ? response.data.origin_direction.neighborhood : ""
        this.origin_addres_2 = ( response.data.origin_direction.direction_2 != null ) ? response.data.origin_direction.direction_2 : ""
        this.origin_postal_code = ( response.data.origin_direction.postal_code != null ) ? response.data.origin_direction.postal_code : ""
      }

      if(response.data.destination_direction != null){
        this.destination_full_name = ( response.data.destination_direction.full_name != null ) ? response.data.destination_direction.full_name : ""
        this.destination_email = ( response.data.destination_direction.email != null ) ? response.data.destination_direction.email : ""
        this.destination_phone = ( response.data.destination_direction.phone != null ) ? response.data.destination_direction.phone : ""
        this.destination_addres_1 = ( response.data.destination_direction.direction_1 != null ) ? response.data.destination_direction.direction_1 : ""
        this.destination_neighborhood = ( response.data.destination_direction.neighborhood != null ) ? response.data.destination_direction.neighborhood : ""
        this.destination_addres_2 = ( response.data.destination_direction.direction_2 != null ) ? response.data.destination_direction.direction_2 : ""
      }
      
      if(response.data.user != null){
        this.user_first_name = ( response.data.user.user_first_name != null ) ? response.data.user.user_first_name : ""
        this.user_last_name = ( response.data.user.user_last_name != null ) ? response.data.user.user_last_name : ""
        this.user_phone = ( response.data.user.user_phone != null ) ? response.data.user.user_phone : ""
        this.user_email = ( response.data.user.user_email != null ) ? response.data.user.user_email : ""
      }
      this.created = true

      
    }else{
      this.created = true

    }

    
    
  },
  mounted(){

    const url = "https://enviaya.com.mx/api/v1/carta_porte_catalogues?api_key=df7a79d6f3426d91c96219ecc4c6724c&name=c_ClaveProdServCP"
    axios.get(url).then(response => {
      this.suggestionsClaveProd = response.data.catalogues
    })

    const url2 = "https://enviaya.com.mx/api/v1/carta_porte_catalogues?api_key=df7a79d6f3426d91c96219ecc4c6724c&name=c_ClaveUnidadPeso"
    axios.get(url2).then(response => {
      this.suggestionsClaveUnidadPeso = response.data.catalogues
    })



  

    

  },
  data() {
    return {

     created:false,
      
      suggestionsOrigin:[],
      suggestionsDestination:[],

      destination_direction:{
        value:''
      },
      origin_direction:{
        value:'',
      },

      suggestionsClaveProd:[],
      suggestionsClaveUnidadPeso:[],
      

      currentStep: 1,

      id:null,
      shiphuk_shipment_id:null,
      origin_direction_id:null,
      destination_direction_id:null,
      destination_type:"",
      rates_based_on:"",
      needed_delivery_time:"",
      shipment_type:"",
      package_type:"",
      weight:null,
      weight_unit:"kg",
      height:null,
      length:null,
      width:null,
      dimension_unit:"cm",
      packaging_category:{
        key:"",
        value:"",
      },
      product_category:{
        key:"",
        value:"",
      },
      content:null,
      value:null,
      value_currency:"MXN",
      insured:false,
      insured_value:null,
      insured_value_currency:"MXN",
      pickup:false,
      pickup_date:null,
      currency:"MXN",
      created_at:null,
      updated_at:null,

      origin_full_name:"",
      origin_email:"",
      origin_phone:"",
      origin_addres_1:"",
      origin_neighborhood:"",
      origin_addres_2:"",
      origin_postal_code:"",
      origin_city:"",
      origin_municipality:"",
      origin_state:"",
      origin_district:"",

      destination_full_name:"",
      destination_email:"",
      destination_phone:"",
      destination_addres_1:"",
      destination_neighborhood:"",
      destination_addres_2:"",
      destination_postal_code:"",
      destination_city:"",
      destination_municipality:"",
      destination_state:"",
      destination_district:"",

      user_id:"",
      user_first_name:"",
      user_last_name:"",
      user_phone:"",
      user_email:"",

      rates:[]

    
      

    };
  },
  methods: {

    generateDynamicHref(rate) {
      

      
      const searchParams = new URLSearchParams();
      
      if(this.content != ""){
        searchParams.append('content', this.content);
      }

      if(this.origin_full_name != ""){
        searchParams.append('origin_full_name', this.origin_full_name);

        const partes = this.origin_full_name.split(' ');

        if (partes.length === 2) {
          const firstName = partes[0];
          const lastName = partes[1];

          searchParams.append('user_first_name', firstName);
          searchParams.append('user_last_name', lastName);
        
        }

        if (partes.length === 3) {
          const firstName = partes[0]+" "+partes[1];
          const lastName = partes[2];
          
          searchParams.append('user_first_name', firstName);
          searchParams.append('user_last_name', lastName);

        }

      }

      if(this.user_email != ""){
        searchParams.append('user_email', this.user_email);
      }

      if(this.origin_email != ""){
        searchParams.append('origin_email', this.origin_email);
        
      }

      if(this.origin_phone != ""){
        searchParams.append('origin_phone', this.origin_phone);
      }

      if(this.origin_addres_1 != ""){
        //searchParams.append('origin_direction_1', this.origin_addres_1);
      }

      if(this.origin_addres_2 != ""){
        //searchParams.append('origin_direction_2', this.origin_addres_2);
      }

   

      if(this.destination_full_name != ""){
        searchParams.append('destination_full_name', this.destination_full_name);
      }

      if(this.destination_email != ""){
        searchParams.append('destination_email', this.destination_email);
      }

      if(this.destination_phone != ""){
        searchParams.append('destination_phone', this.destination_phone);
      }

      if(this.destination_addres_1 != ""){
        //searchParams.append('destination_direction_1', this.destination_addres_1);
      }

      if(this.destination_addres_2 != ""){
        //searchParams.append('destination_direction_2', this.destination_addres_2);
      }


      const queryString = searchParams.toString();

      const choose_quote_public_url = `https://ventapublico.enviaya.com.mx/choose_quote_public/${rate.shipment_id}/${rate.id}?${queryString}`

      //
      axios.get(choose_quote_public_url).then(response => {
        console.log(response)
        const payment_url = `https://ventapublico.enviaya.com.mx/payment_public/${rate.shipment_id}`
        window.open(payment_url, '_blank');
      })
      
  
    },
    updateDirections(){
      const origin_data = {
              user_id:this.user_id,
              full_name:this.origin_full_name,
              email:this.origin_email,
              phone:this.origin_phone,
              direction_1:this.origin_addres_1,
              neighborhood:this.origin_neighborhood,
              direction_2:this.origin_addres_2,
          
            };

            const origin_data_filtered = {};

            for (let k in origin_data) {
                  
              if(origin_data[k] != null && origin_data[k] != ""){
                origin_data_filtered[k] = origin_data[k]
              }
              
            }

            const destination_data = {
              user_id:this.user_id,
              full_name:this.destination_full_name,
              email:this.destination_email,
              phone:this.destination_phone,
              direction_1:this.destination_addres_1,
              neighborhood:this.destination_neighborhood,
              direction_2:this.destination_addres_2,
          
            };

            const destonation_data_filtered = {};

            for (let k in destination_data) {
                  
              if(destination_data[k] != null && destination_data[k] != ""){
                destonation_data_filtered[k] = destination_data[k]
              }
              
            }

            if(this.id == null){

                const url = backendUrl+'/shipments';

                axios.post(url, {})
                .then(response => {

                  this.id = response.data.shipment.id
                  this.origin_direction_id = response.data.shipment.origin_direction_id
                  this.destination_direction_id = response.data.shipment.destination_direction_id
                  
                  console.log('Respuesta del servidor:', response.data);

                  const url = backendUrl+'/directions/'+this.origin_direction_id;

                  axios.put(url, origin_data_filtered)
                  .then(response => {
                  
                    console.log('Respuesta del servidor:', response.data);
                  })
                  .catch(error => {
                    // Maneja los errores de la solicitud
                    console.error('Error al realizar la solicitud:', error);
                  });

                  const url2 = backendUrl+'/directions/'+this.destination_direction_id;

                  axios.put(url2, destonation_data_filtered)
                  .then(response => {
                  
                    console.log('Respuesta del servidor:', response.data);
                  })
                  .catch(error => {
                    // Maneja los errores de la solicitud
                    console.error('Error al realizar la solicitud:', error);
                  });

                })
                .catch(error => {
                  // Maneja los errores de la solicitud
                  console.error('Error al realizar la solicitud:', error);
                });


            }else{

                  const url = backendUrl+'/directions/'+this.origin_direction_id;

                  axios.put(url, origin_data_filtered)
                  .then(response => {
                  
                    console.log('Respuesta del servidor:', response.data);
                  })
                  .catch(error => {
                    // Maneja los errores de la solicitud
                    console.error('Error al realizar la solicitud:', error);
                  });

                  const url2 = backendUrl+'/directions/'+this.destination_direction_id;

                  axios.put(url2, destonation_data_filtered)
                  .then(response => {
                  
                    console.log('Respuesta del servidor:', response.data);
                  })
                  .catch(error => {
                    // Maneja los errores de la solicitud
                    console.error('Error al realizar la solicitud:', error);
                  });



            }
    },
    async nextStep2() {

      if(this.user_id != ""){
      
          
            this.updateDirections()
            this.currentStep++

        }else{

          if(this.user_first_name != "" && this.user_email != "" && this.user_phone != "" && this.user_last_name != ""){

            const user_data = {
              user_first_name:this.user_first_name,
              user_last_name:this.user_last_name,
              user_phone:this.user_phone,
              user_email:this.user_email,
            };

            const user_data_filtered = {};

            for (let k in user_data) {
                  
              if(user_data[k] != null && user_data[k] != ""){
                user_data_filtered[k] = user_data[k]
              }
              
            }

            const url = backendUrl+'/shipments/'+this.id;

            const response = await axios.put(url,user_data_filtered);
            this.user_id = response.data.shipment.user_id;
            this.updateDirections();

            this.currentStep++
          }



        }

       
    },

    nextStep() {

      const packaging_category = ( this.packaging_category.key != "" &&  this.packaging_category.value != "" ) ? `${this.packaging_category.value} ( ${this.packaging_category.key} ) ` : null
      const product_category = ( this.product_category.key != "" &&  this.product_category.value != "" ) ? `${this.product_category.value} ( ${this.product_category.key} ) ` : null

      const data = {
          shiphuk_shipment_id:this.shiphuk_shipment_id,
          origin_direction_id:this.origin_direction_id,
          destination_direction_id:this.destination_direction_id,
          destination_type:this.destination_type,
          rates_based_on:this.rates_based_on,
          needed_delivery_time:this.needed_delivery_time,
          shipment_type:this.shipment_type,
          package_type:this.package_type,
          weight:this.weight,
          weight_unit:this.weight_unit,
          height:this.height,
          length:this.length,
          width:this.width,
          dimension_unit:this.dimension_unit,
          packaging_category:packaging_category,
          product_category: product_category,
          content:this.content,
          value:this.value,
          value_currency:this.value_currency,
          insured:this.insured,
          insured_value:this.insured_value,
          insured_value_currency:this.insured_value_currency,
          pickup:this.pickup,
          pickup_date:this.pickup_date,
          currency:this.currency,

        };

        const data_filtered = {};

        for (let k in data) {
              
          if(data[k] != null && data[k] != ""){
            data_filtered[k] = data[k]
          }
          
        }
     
      if(this.id == null){

        const url = backendUrl+'/shipments';

        axios.post(url, data_filtered)
        .then(response => {

          this.id = response.data.shipment.id
          this.origin_direction_id = response.data.shipment.origin_direction_id
          this.destination_direction_id = response.data.shipment.destination_direction_id

          console.log('Respuesta del servidor:', response.data);
          this.currentStep++
        })
        .catch(error => {
          // Maneja los errores de la solicitud
          console.error('Error al realizar la solicitud:', error);
        });


      }else{

        const url = backendUrl+'/shipments/'+this.id;

        const data = {
          shiphuk_shipment_id:this.shiphuk_shipment_id,
          origin_direction_id:this.origin_direction_id,
          destination_direction_id:this.destination_direction_id,
          destination_type:this.destination_type,
          rates_based_on:this.rates_based_on,
          needed_delivery_time:this.needed_delivery_time,
          shipment_type:this.shipment_type,
          package_type:this.package_type,
          weight:this.weight,
          weight_unit:this.weight_unit,
          height:this.height,
          length:this.length,
          width:this.width,
          dimension_unit:this.dimension_unit,
          packaging_category:packaging_category,
          product_category: product_category,
          content:this.content,
          value:this.value,
          value_currency:this.value_currency,
          insured:this.insured,
          insured_value:this.insured_value,
          insured_value_currency:this.insured_value_currency,
          pickup:this.pickup,
          pickup_date:this.pickup_date,
          currency:this.currency,

        };

        const data_filtered = {};

        for (let k in data) {
              
          if(data[k] != null && data[k] != ""){
            data_filtered[k] = data[k]
          }
          
        }

        axios.put(url, data_filtered)
        .then(response => {
         
          console.log('Respuesta del servidor:', response.data);
        })
        .catch(error => {
          // Maneja los errores de la solicitud
          console.error('Error al realizar la solicitud:', error);
        });

        this.currentStep++

      }
     
    },
    prevStep() {
      if (this.currentStep > 1) {
        this.currentStep--;
      }
    },
    submitForm() {
      // Lógica para enviar el formulario
    },
    onInputDestinationChange(item) {

        if(item.input.length >= 3){
          
          const apiUrl = 'https://ventapublico.enviaya.com.mx/shipping/address_short_search.json?query='+item.input+'&country=mx';

          axios .get(apiUrl).then((response) => {
            
            this.suggestionsDestination = response.data

          }).catch((error) => {
            console.error('Error al hacer la solicitud:', error);
          });
          
          

          
        }

      },
      onInputOriginChange(item) {

        if(item.input.length >= 3){
          
          const apiUrl = 'https://ventapublico.enviaya.com.mx/shipping/address_short_search.json?query='+item.input+'&country=mx';

          axios .get(apiUrl).then((response) => {

            this.suggestionsOrigin = response.data

          }).catch((error) => {
            console.error('Error al hacer la solicitud:', error);
          });
          
        }

      },
      itemProjectionFunction(item){
        return item.value
      },
      itemProjectionClaveProd(item){
        if(item.value != "" && +item.key != ""){
          return item.value + "( "+item.key+" )"
        }
       
      },
      originItemProjection(item){

        return item.value
      },
      setOriginAddress(direction){
        
        if(direction.value != ""){


          const copy = Object.assign({}, direction);
          const shiphuk_direction_id = (typeof direction.shiphuk_direction_id != 'undefined') ? direction.shiphuk_direction_id  : direction.id

          // Renombra la propiedad `id` a `shiphuk_direction_id`
          delete copy.id;
          delete copy.class_name;

          copy.shiphuk_direction_id = shiphuk_direction_id
        
          
          this.origin_neighborhood = ( direction.neighborhood != null ) ? direction.neighborhood : ""
          this.origin_postal_code = ( direction.postal_code != null ) ? direction.postal_code : ""
          this.origin_city = ( direction.city != null ) ? direction.city : ""
          this.origin_municipality = ( direction.municipality != null ) ? direction.municipality : ""
          this.origin_state = ( direction.state != null ) ? direction.state : ""
          this.origin_district = ( direction.district != null ) ? direction.district : ""


          const data_filtered = {};

          for (let k in copy) {
                
            if(copy[k] != null && copy[k] != ""){
              data_filtered[k] = copy[k]
            }
            
          }
        
          if(this.id == null && direction.value != ""){

            const url = backendUrl+'/shipments';

            axios.post(url, {})
            .then(response => {

              this.id = response.data.shipment.id
              this.origin_direction_id = response.data.shipment.origin_direction_id
              this.destination_direction_id = response.data.shipment.destination_direction_id
              
              
              console.log('Respuesta del servidor:', response.data);

              const url = backendUrl+'/directions/'+this.origin_direction_id;

              axios.put(url, data_filtered)
              .then(response2 => {
                
                this.origin_direction =  response2.data.direction
                console.log('Respuesta del servidor:', response2.data);
              })
              .catch(error => {
                // Maneja los errores de la solicitud
                console.error('Error al realizar la solicitud:', error);
              });

            


            })
            .catch(error => {
              // Maneja los errores de la solicitud
              console.error('Error al realizar la solicitud:', error);
            });


          }else{
          
            if(this.origin_direction_id != null){

              const url = backendUrl+'/directions/'+this.origin_direction_id;

              axios.put(url, data_filtered)
              .then(response => {
                
                this.origin_direction =  response.data.direction
                console.log('Respuesta del servidor:', response.data);
              })
              .catch(error => {
                // Maneja los errores de la solicitud
                console.error('Error al realizar la solicitud:', error);
              });

            }else{

                  console.log(data_filtered)
                  const url = backendUrl+'/directions/';

                  axios.post(url, data_filtered)
                  .then(response => {
                    
                    this.origin_direction_id = response.data.direction.id
                    this.origin_direction =  response.data.direction
                  
                  })
                  .catch(error => {
                    // Maneja los errores de la solicitud
                    console.error('Error al realizar la solicitud:', error);
                  });

              


            }

            

          }
         
        }
        
        
        

      },
      setDestinationAddress(direction){

          if(direction.value != ""){
              const copy = Object.assign({}, direction);
              const shiphuk_direction_id = (typeof direction.shiphuk_direction_id != 'undefined') ? direction.shiphuk_direction_id  : direction.id
              // Renombra la propiedad `id` a `shiphuk_direction_id`
              delete copy.id;
              delete copy.class_name;

              copy.shiphuk_direction_id = shiphuk_direction_id

              this.destination_neighborhood = ( direction.neighborhood != null ) ? direction.neighborhood : ""
              this.destination_postal_code = ( direction.postal_code != null ) ? direction.postal_code : ""
              this.destination_city = ( direction.city != null ) ? direction.city : ""
              this.destination_municipality = ( direction.municipality != null ) ? direction.municipality : ""
              this.destination_state = ( direction.state != null ) ? direction.state : ""
              this.destination_district = ( direction.district != null ) ? direction.district : ""

              const data_filtered = {};

              for (let k in copy) {
                    
                if(copy[k] != null && copy[k] != ""){
                  data_filtered[k] = copy[k]
                }
                
              }

              if(this.id == null && direction.value != ""){

                const url = backendUrl+'/shipments';

                axios.post(url, {})
                .then(response => {

                  this.id = response.data.shipment.id
                  this.origin_direction_id = response.data.shipment.origin_direction_id
                  this.destination_direction_id = response.data.shipment.destination_direction_id
                  
                  console.log('Respuesta del servidor:', response.data);

                  const url = backendUrl+'/directions/'+this.destination_direction_id;

                  axios.put(url, data_filtered)
                  .then(response2 => {
                    
                    this.destination_direction = response2.data.direction
                    console.log('Respuesta del servidor:', response2.data);
                  })
                  .catch(error => {
                    // Maneja los errores de la solicitud
                    console.error('Error al realizar la solicitud:', error);
                  });

                


                })
                .catch(error => {
                  // Maneja los errores de la solicitud
                  console.error('Error al realizar la solicitud:', error);
                });


              }else{

                if(this.destination_direction_id != null){

                  const url = backendUrl+'/directions/'+this.destination_direction_id;

                  axios.put(url, data_filtered)
                  .then(response => {
                    
                    this.destination_direction = response.data.direction
                    console.log('Respuesta del servidor:', response.data);
                  })
                  .catch(error => {
                    // Maneja los errores de la solicitud
                    console.error('Error al realizar la solicitud:', error);
                  });

                }else{

                  const url = backendUrl+'/directions/';

                    axios.post(url, data_filtered)
                    .then(response => {
                      
                      this.destination_direction_id = response.data.direction.id
                      this.destination_direction =  response.data.direction
                    
                    })
                    .catch(error => {
                      // Maneja los errores de la solicitud
                      console.error('Error al realizar la solicitud:', error);
                    });

                }

              }
          }
      },
      setClaveProd(clave){
        console.log(clave)
        this.product_category = clave
      },
      setClaveUnidadPeso(clave){
        this.packaging_category = clave
      },
      cotizar(){

        const _this = this

        const parcel = {
            "quantity": "1",
            "length":  Math.ceil(this.length),
            "width":  Math.ceil(this.width),
            "height":  Math.ceil(this.height),
            "weight":  Math.ceil(this.weight),
            "content": this.content,
            "value": this.value,
            "value_currency":"MXN",
            "product_category": this.product_category.key,
            "packaging_category": this.packaging_category.key

        }

        const parcel_filtered = {};

        for (let k in parcel) {
              
          if(parcel[k] != null && parcel[k] != ""){
            parcel_filtered[k] = parcel[k]
          }
          
          
        }

        if(typeof parcel_filtered.value == 'undefined'){
          delete parcel_filtered.value_currency
        }


        const buildData = {
            "address_id_from": this.origin_direction.shiphuk_direction_id,
            "address_id_to":  this.destination_direction.shiphuk_direction_id,
            "country_from": "mx",
            "country_to": "mx",
            "quote_from": this.origin_direction.value,
            "quote_to": this.destination_direction.value,
            "shipment_type": 2,
            //"insured_amount_currency": "MXN",
            "parcel_weight_unit": "kg",
            "doc_enviaya_account_id": 0,
            "paq_enviaya_account_id": 0,
            "shipment": {
              "origin_full_name":this.origin_full_name,
              "origin_email":this.origin_email,
              "origin_phone":this.origin_phone,
              "origin_direction_1":this.origin_addres_1,
              "origin_direction_2":this.origin_addres_2,
              "destination_full_name":this.destination_full_name,
              "destination_email":this.destination_email,
              "destination_phone":this.destination_phone,
              "destination_direction_1":this.destination_addres_1,
              "destination_direction_2":this.destination_addres_2,
              "parcels": {
                  "0": parcel_filtered
              }
            }
        }

      

        const apiUrl = 'https://ventapublico.enviaya.com.mx/api/v2/shipments/build';

        axios.post(apiUrl,buildData).then((response) => {

            const buildResponse = response.data

            const shipmentQuotesData = {
              "shipment_id": buildResponse.id,
              "kw": null
            }

            const shipmentQuotesUrl = 'https://ventapublico.enviaya.com.mx/api/v2/shipment_quotes';

            axios.post(shipmentQuotesUrl,shipmentQuotesData).then(() => {

              const shipmentOffersUrl = 'https://ventapublico.enviaya.com.mx/api/v2/shipments/'+buildResponse.id+'/offers'

              setTimeout(function(){
                  
                axios.get(shipmentOffersUrl).then((response) => {
                
                    const shipmentOffersResponse = response.data
                    _this.rates = shipmentOffersResponse.shipment_offers

                }).catch((error) => {
                  console.error('Error al hacer la solicitud:', error);
                });

              }, 5000);


            }).catch((error) => {
              console.error('Error al hacer la solicitud:', error);
            });

           
            /*
            var rates = [];

            var shipment_id = null;

            for (const carrier in ratesResponse) {



                if(carrier != "warning"){

                  if(shipment_id == null){
                    shipment_id = carrier.shionent_id
                  }

                  rates = [...rates, ...ratesResponse[carrier]];
                }
                
            }

            
            this.rates = rates

            if(rates.length > 0){


              const data = {
                shiphuk_shipment_id: rates[0].shipment_id,
                rates: rates
              }

              const url = backendUrl+'/shipments/'+this.id;

              axios.put(url, data)
              .then(response => {
              
                console.log('Respuesta del servidor:', response.data);
              })
              .catch(error => {
                // Maneja los errores de la solicitud
                console.error('Error al realizar la solicitud:', error);
              });

            }
            */



      }).catch((error) => {
        console.error('Error al hacer la solicitud:', error);
      });

      }
  },
};
</script>

<style scoped>

.select, select{
  width: 100%;
}


/* Estilos de Bulma para formatear el formulario con pasos según sea necesario */
</style>
