import { createStore } from 'vuex';
import axios from 'axios';
//import VueRouter from 'vue-router';

export default createStore({
    state: {
        current_step: 1,
        api_url: process.env.VUE_APP_BACKEND_URL,
        wa_number: process.env.VUE_APP_WA_NUMBER,
        return_wa:false,

        //START::SHIPMENT
        shipment_id:"",
        origin_direction_id:"",
        destination_direction_id:"",
        user:{},
        origin_direction:{},
        destination_direction:{},
        destination_type:"",
        weight_unit:"",
        dimension_unit:"",
        length:"",
        height:"",
        width:"",
        weight:"",
        value_currency:"",
        insured_value_currency:"",
        pickup:"",
        currency:"",
        rates_based_on:"",
        needed_delivery_time:"",
        content:"",
         //END::SHIPMENT
    },
    mutations: {
        SET_CURRENT_STEP(state,value){
            state.current_step = value
        },
        SET_RETURN_WA(state,value){
            state.return_wa = value
        },
        SET_DESTINATION_TYPE(state,value){
            state.destination_type = value
        },
        SET_SHIPMENT_DATA(state,values){
          
            if(state.shipment_id == "" && typeof values.id != 'undefined'){
                state.shipment_id = values.id
            }

            for (const propiedad in values) {

                if(values[propiedad] != null && propiedad != 'id'){
                    state[propiedad] = values[propiedad]

                    if(['length','width','weight','height'].indexOf(propiedad) !== -1){
                        state[propiedad] = parseFloat(values[propiedad])
                    }
                    

                }
                
            }

        },
        SET_DIRECTION_DATA(state,direction){
            if(direction.type == 'origin'){
                state.origin_direction = direction
            }else if(direction.type == 'destination'){
                state.destination_direction = direction
            }
        },

    },
    actions: {
        setCurrentStep(context,step){
            context.commit('SET_CURRENT_STEP',step);
        },
        setReturnWA(context,value){
            context.commit('SET_RETURN_WA',value);
        },

        async createShipment(context){
      
            const url = `${this.state.api_url}/shipments`
            
            const response = await axios.post(url,{});
            const shipment = response.data.shipment
            

            context.commit('SET_SHIPMENT_DATA',shipment);

         
            

        },
        async updateShipment(context,values){

            if(this.state.shipment_id == ""){
                await this.dispatch('createShipment')
            } 
            
            const url = `${this.state.api_url}/shipments/${this.state.shipment_id}`
            
            axios.put(url,values).then(response => {
                const shipment = response.data.shipment
                context.commit('SET_SHIPMENT_DATA',shipment);
            })
            

        },
        
        setShipmentData(context,values){

            context.commit('SET_SHIPMENT_DATA',values);

        },

        setDestinationType(context,value){

            context.commit('SET_DESTINATION_TYPE',value);

            context.dispatch('updateShipment',{
                destination_type:value
            })
            

        },
        async setdirectionData(context,data){
            
           

            if(data.type == 'origin'){
   
                if(this.state.origin_direction_id == ""){

                    const url = `${this.state.api_url}/directions`

                    try{
                        const response = await axios.post(url,data);
                        const direction = response.data.direction
                        context.dispatch('updateShipment',{
                            origin_direction_id:direction.id
                        })
                        direction.type = 'origin'
                        context.commit('SET_DIRECTION_DATA',direction);
                    }catch(error){
                        console.error(error)
                    }

                }else{

                    const url = `${this.state.api_url}/directions/${this.state.origin_direction_id}`

                    try{
                        const response = await axios.put(url,data);
                        const direction = response.data.direction
                        direction.type = 'origin'
                        context.commit('SET_DIRECTION_DATA',direction);
                    }catch(error){
                        console.error(error)
                    }

                }                

            }else if(data.type == 'destination'){

                if(this.state.destination_direction_id == ""){

                    const url = `${this.state.api_url}/directions`

                    try{
                        const response = await axios.post(url,data);
                        const direction = response.data.direction
                        context.dispatch('updateShipment',{
                            destination_direction_id:direction.id
                        })
                        direction.type = 'destination'
                        context.commit('SET_DIRECTION_DATA',direction);
                    }catch(error){
                        console.error(error)
                    }

                }else{

                    const url = `${this.state.api_url}/directions/${this.state.destination_direction_id}`

                    try{
                        const response = await axios.put(url,data);
                        const direction = response.data.direction
                        direction.type = 'destination'
                        context.commit('SET_DIRECTION_DATA',direction);
                    }catch(error){
                        console.error(error)
                    }

                }      

            }

        },
        async injectShipmentData(context,data){

            const searchParams = new URLSearchParams();
            
            if(typeof this.state.user.user_first_name != "undefined" && this.state.user.user_first_name != null && this.state.user.user_first_name != ""){
                searchParams.append('user_first_name', this.state.user.user_first_name);
            }

            if(typeof this.state.user.user_last_name != "undefined" && this.state.user.user_last_name != null && this.state.user.user_last_name != ""){
                searchParams.append('user_last_name', this.state.user.user_last_name);
            }

            if(typeof this.state.user.user_email != "undefined" && this.state.user.user_email != null && this.state.user.user_email != ""){
                searchParams.append('user_email', this.state.user.user_email);
            }

            if(typeof this.state.user.user_phone != "undefined" && this.state.user.user_phone != null && this.state.user.user_phone != ""){
                searchParams.append('user_phone', this.state.user.user_phone);
            }

            if(this.state.content != ""){
                searchParams.append('content', this.state.content);
            }

            if(typeof this.state.origin_direction.full_name != "undefined" && this.state.origin_direction.full_name != null && this.state.origin_direction.full_name != ""){
                searchParams.append('origin_full_name', this.state.origin_direction.full_name);
            }

            if(typeof this.state.origin_direction.email != "undefined" && this.state.origin_direction.email != null && this.state.origin_direction.email != ""){
                searchParams.append('origin_email', this.state.origin_direction.email);
            }

            if(typeof this.state.origin_direction.direction_1 != "undefined" && this.state.origin_direction.direction_1 != null && this.state.origin_direction.direction_1 != ""){
                searchParams.append('origin_direction_1', this.state.origin_direction.direction_1);
            }

            if(typeof this.state.origin_direction.direction_2 != "undefined" && this.state.origin_direction.direction_2 != null && this.state.origin_direction.direction_2 != ""){
                searchParams.append('origin_direction_2', this.state.origin_direction.direction_2);
            }

            if(typeof this.state.origin_direction.neighborhood != "undefined" && this.state.origin_direction.neighborhood != null && this.state.origin_direction.neighborhood != ""){
                searchParams.append('origin_neighborhood', this.state.origin_direction.neighborhood);
            }



            if(typeof this.state.destination_direction.full_name != "undefined" && this.state.destination_direction.full_name != null && this.state.destination_direction.full_name != ""){
                searchParams.append('destination_full_name', this.state.destination_direction.full_name);
            }

            if(typeof this.state.destination_direction.email != "undefined" && this.state.destination_direction.email != null && this.state.destination_direction.email != ""){
                searchParams.append('destination_email', this.state.destination_direction.email);
            }

            if(typeof this.state.destination_direction.direction_1 != "undefined" && this.state.destination_direction.direction_1 != null && this.state.destination_direction.direction_1 != ""){
                searchParams.append('destination_direction_1', this.state.destination_direction.direction_1);
            }

            if(typeof this.state.destination_direction.direction_2 != "undefined" && this.state.destination_direction.direction_2 != null && this.state.destination_direction.direction_2 != ""){
                searchParams.append('destination_direction_2', this.state.destination_direction.direction_2);
            }

            if(typeof this.state.destination_direction.neighborhood != "undefined" && this.state.destination_direction.neighborhood != null && this.state.destination_direction.neighborhood != ""){
                searchParams.append('destination_neighborhood', this.state.destination_direction.neighborhood);
            }



            const queryString = searchParams.toString();

            const choose_quote_public_url = `https://ventapublico.enviaya.com.mx/choose_quote_public/${data.shiphuk_shipment_id}/${data.shiphuk_rate_id}?${queryString}`

            await axios.get(choose_quote_public_url);
        }
    }
    
})