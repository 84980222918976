import { createApp } from 'vue';
import App from './App.vue';
import router from './router'; // Importa la instancia de Vue Router
import store from './store'; // Importa el almacén Vuex


import 'bulma/css/bulma.css'
import './assets/css/app.css';

const app = createApp(App);

// Agrega Vue Router a la aplicación
app.use(router);
app.use(store);

app.mount('#app');