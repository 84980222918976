<template>
    <div>
        <div class="block">
            <h1 class="title has-text-centered">Tus artículos se enviarán en:</h1>
            <div class="columns">
                <div class="column">
                    <div class="box is-clickable" :class="{ 'is-active': package_type == 'Sobre' }"
                        @click="update('Sobre')">
                        <h2 class="subtitle  has-text-centered">Sobre o Bolsa</h2>
                    </div>
                </div>
                <div class="column">
                    <div class="box is-clickable" :class="{ 'is-active': package_type == 'Caja' }" @click="update('Caja')">
                        <h2 class="subtitle  has-text-centered">Caja</h2>
                    </div>
                </div>
            </div>
        </div>
        <div class="block">
            <div class="field">
                <label class="label">Largo (cm)</label>
                <div class="control">
                    <input class="input" type="tel"  v-model="tmp_length">
                </div>
            </div>
            <div class="field">
                <label class="label">Ancho (cm)</label>
                <div class="control">
                    <input class="input" type="tel"  v-model="tmp_width">
                </div>
            </div>
            <div class="field">
                <label class="label">Alto (cm)</label>
                <div class="control">
                    <input class="input" type="tel"  v-model="tmp_height">
                </div>
            </div>
            <div class="field">
                <label class="label">Peso (kg)</label>
                <div class="control">
                    <input class="input" type="tel"  v-model="tmp_weight">
                </div>
            </div>
        </div>
        <div class="block" id="footer-actions">
            <button class="button is-success" @click="redirectMB">Finalizar</button>
        </div>
    </div>
</template>
    
<script>

import { mapState, mapActions } from 'vuex';

import axios from 'axios';

export default {
    name: 'DimensionPackage',
    created(){
        this.tmp_length = this.length
        this.tmp_width = this.width
        this.tmp_height = this.height
        this.tmp_weight = this.weight

    },
    data(){
        return{
            tmp_length:"",
            tmp_width:"",
            tmp_height:"",
            tmp_weight:"",
        }
    },
    computed: {
        ...mapState(['current_step','wa_number','shipment_id','package_type','length','width','height','weight'])
    },
    methods:{
        ...mapActions(['updateShipment']),
        update(package_type){
            
            this.updateShipment({
                'package_type':package_type
            })
        },
        redirectMB(){

            this.updateShipment({
                'length':this.tmp_length,
                'width':this.tmp_width,
                'height':this.tmp_height,
                'weight':this.tmp_weight,
            });
       
        
            const url = "https://flows.messagebird.com/flows/invocations/webhooks/d98c901a-287e-4f49-9a08-27d8c10ecd82"
            const data = {
                identifier:this.shipment_id.toString(),
                length: this.tmp_length,
                width: this.tmp_width,
                height: this.tmp_height,
                weight: this.tmp_weight,
            }

            
            
            axios.post(url,data).then(() => {

                
                // Genera la URL del enlace de WhatsApp
                const urlWA = `https://wa.me/${this.wa_number}`;

                // Redirige a la conversación de WhatsApp
                window.location.href = urlWA;
            })
        
        }
    },
    

}

</script>

<style scoped>

.box.is-active{
    border:solid 2px orange;
}

#footer-actions button{
    width: 100%;
}


</style>

