<template>
    <div class="block">
        <h1 class="title has-text-centered">¿Cuál es el <strong>destino de tu envío?</strong></h1>
        <div class="columns">
            <div class="column"><div class="box is-clickable" :class="{ 'is-active': destination_type == 'Local' }" @click="setDestinationType('Local')"><h2 class="subtitle  has-text-centered">Local</h2></div></div>
            <div class="column"><div class="box is-clickable"  :class="{ 'is-active': destination_type == 'Nacional' }" @click="setDestinationType('Nacional')"><h2 class="subtitle  has-text-centered">Nacional</h2></div></div>
            <div class="column"><div class="box is-clickable"  :class="{ 'is-active': destination_type == 'Internacional' }" @click="setDestinationType('Internacional')"><h2 class="subtitle  has-text-centered">Internacional</h2></div></div>
        </div>
    </div>

</template>
    
<script>

import { mapState, mapActions } from 'vuex';



export default {
    name: 'ShippingDestination',
    computed: {
        ...mapState(['current_step','destination_type'])
    },
    methods:{
        ...mapActions(['setDestinationType']),
    },
    

}

</script>

<style scoped>

.box.is-active{
    border:solid 2px orange;
}

</style>