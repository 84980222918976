<template>
    <div class="block">
        <h1 class="title has-text-centered">¿Cuándo necesitas que llegue?</h1>
        <div class="columns">
            <div class="column"><div class="box is-clickable" :class="{ 'is-active': needed_delivery_time == 'Hoy' }" @click="update('Hoy')"><h2 class="subtitle  has-text-centered">Hoy</h2></div></div>
            <div class="column"><div class="box is-clickable"  :class="{ 'is-active': needed_delivery_time == 'Mañana' }" @click="update('Mañana')"><h2 class="subtitle  has-text-centered">Mañana</h2></div></div>
            <div class="column"><div class="box is-clickable"  :class="{ 'is-active': needed_delivery_time == 'Esta semana' }" @click="update('Esta semana')"><h2 class="subtitle  has-text-centered">Esta semana</h2></div></div>
            <div class="column"><div class="box is-clickable"  :class="{ 'is-active': needed_delivery_time == 'Sin prisa' }" @click="update('Sin prisa')"><h2 class="subtitle  has-text-centered">Sin prisa</h2></div></div>
        </div>
    </div>
  
</template>
    
<script>

import { mapState, mapActions } from 'vuex'



export default {

    name: 'NeededDeliveryTime',
    computed: {
        ...mapState(['current_step','needed_delivery_time'])
    },
    methods:{
        ...mapActions(['updateShipment']),
        update(needed_delivery_time){
            
            this.updateShipment({
                'needed_delivery_time':needed_delivery_time
            })
        }
    }

}

</script>

<style scoped>

.box.is-active{
    border:solid 2px orange;
}

</style>