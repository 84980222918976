<template>
  <section class="section">
    <div class="container" v-if="created">
      <shipping-destination v-if="current_step == 1"></shipping-destination>
      <rates-based-on v-if="current_step == 2"></rates-based-on>
      <needed-delivery-time v-if="current_step == 3"></needed-delivery-time>
      <origin-destination-short v-if="current_step == 5"></origin-destination-short>
      <dimension-package v-if="current_step ==7"></dimension-package>
      <origin-destination-full v-if="current_step == 11"></origin-destination-full>
    </div>
  </section>
</template>
  
<script>

//import axios from 'axios';
import { mapState, mapActions } from 'vuex';


import ShippingDestination from './forms/ShippingDestination.vue'
import RatesBasedOn from  './forms/RatesBasedOn.vue'
import NeededDeliveryTime from  './forms/NeededDeliveryTime.vue'
import OriginDestinationShort from './forms/OriginDestinationShort.vue'
import DimensionPackage from './forms/DimensionPackage.vue'
import OriginDestinationFull from './forms/OriginDestinationFull.vue'

import axios from 'axios';

export default {
  name: 'HomeMostrador',
  data(){
    return {
      created:false,
    }
  },
  computed: {
    ...mapState(['api_url','shipment_id','current_step']),
  },

  async created() {

    const shipment_id = this.$route.params.shipment_id
    const step = this.$route.params.step

    const return_wa = this.$route.params.return_wa
    
    if(typeof shipment_id != 'undefined' && shipment_id != 0){

      const url = `${this.api_url}/shipments/${shipment_id}`
      
      try{
        const response = await axios.get(url);
        const shipment = response.data;
        this.setShipmentData(shipment)
      }catch(error){
        console.log(error)
       // this.$router.push('/shipments/0/step/'+this.step);

      }
      

    }

    if(typeof step != 'undefined' ){
      this.setCurrentStep(step)
    }

    this.created = true;

    if(typeof return_wa != 'undefined' ){
      this.setReturnWA(true)
    }
   
    

  },
  methods:{
    ...mapActions(['setShipmentData','setCurrentStep','setReturnWA']),
  },
  watch: {
    shipment_id(nuevoValor){
  
      const queryObject = this.$route.query;
      const queryString = Object.keys(queryObject).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(queryObject[key])}`).join('&');
      
      this.$router.push(`/shipments/${nuevoValor}/step/${this.current_step}?${queryString}`);
    }
  },

  components:{
    ShippingDestination,
    RatesBasedOn,
    NeededDeliveryTime,
    OriginDestinationShort,
    OriginDestinationFull,
    DimensionPackage
  }

}
</script>