<template>
    <div class="wrapper">

    
    <div class="block">
        <h1 class="title has-text-centered">Completa tus datos</h1>
        <h2 class="subtitle">Datos personales y Contenido del envío</h2>
        <div class="field">
            <label class="label">Nombre(s) * </label>
            <div class="control">
                <input class="input" type="text"  v-model="user_first_name">
            </div>
        </div>
        <div class="field">
            <label class="label">Apellido(s) * </label>
            <div class="control">
                <input class="input" type="text"  v-model="user_last_name">
            </div>
        </div>
        <div class="field">
            <label class="label">Correo * </label>
            <div class="control">
                <input class="input" type="text"  v-model="user_email">
            </div>
        </div>
        <div class="field">
            <label class="label">Teléfono * </label>
            <div class="control">
                <input class="input" type="tel"  v-model="user_phone">
            </div>
        </div>
        <div class="field">
            <label class="label">Contenido de tu envío* </label>
            <div class="control">
                <input class="input" type="text"  v-model="shipment_content">
            </div>
        </div>
        <h2 class="subtitle">Origen</h2>
        <div class="field">
            <label class="label">Nombre(s) y Apellido(s) *</label>
            <div class="control">
                <input class="input" type="text"  v-model="origin_full_name">
            </div>
        </div>
        <div class="field">
            <label class="label">Teléfono * </label>
            <div class="control">
                <input class="input" type="tel"  v-model="origin_phone">
            </div>
        </div>
        <div class="field">
            <label class="label">Correo electrónico  </label>
            <div class="control">
                <input class="input" type="text"  v-model="origin_email">
            </div>
        </div>
        <div class="field">
            <label class="label">Calle y Número * </label>
            <div class="control">
                <input class="input" type="text"  v-model="origin_direction_1">
            </div>
        </div>
        <div class="field">
            <label class="label">Referencias (entre calles, color de la fachada, etc.) </label>
            <div class="control">
                <input class="input" type="text"  v-model="origin_direction_2">
            </div>
        </div>
        <div class="field">
            <label class="label">Colonia </label>
            <div class="control">
                <input class="input" type="text"  v-model="origin_neighborhood">
            </div>
        </div>
        <h2 class="subtitle">Destino</h2>
        <div class="field">
            <label class="label">Nombre(s) y Apellido(s) *</label>
            <div class="control">
                <input class="input" type="text"  v-model="destination_full_name">
            </div>
        </div>
        <div class="field">
            <label class="label">Teléfono * </label>
            <div class="control">
                <input class="input" type="tel"  v-model="destination_phone">
            </div>
        </div>
        <div class="field">
            <label class="label">Correo electrónico  </label>
            <div class="control">
                <input class="input" type="text"  v-model="destination_email">
            </div>
        </div>
        <div class="field">
            <label class="label">Calle y Número * </label>
            <div class="control">
                <input class="input" type="text"  v-model="destination_direction_1">
            </div>
        </div>
        <div class="field">
            <label class="label">Referencias (entre calles, color de la fachada, etc.) </label>
            <div class="control">
                <input class="input" type="text"  v-model="destination_direction_2">
            </div>
        </div>
        <div class="field">
            <label class="label">Colonia </label>
            <div class="control">
                <input class="input" type="text"  v-model="destination_neighborhood">
            </div>
        </div>
    </div>
    <div class="block" id="footer-actions">
        <button class="button is-success" @click="nextStep">Continuar</button>
    </div>
    </div>
   
</template>
    
<script>

import { mapState, mapActions } from 'vuex';


export default {
    name: 'OriginDestinationFull',
    created(){

        this.user_first_name = (this.user.user_first_name != null) ? this.user.user_first_name : ""
        this.user_last_name = (this.user.user_last_name != null) ? this.user.user_last_name : ""
        this.user_email = (this.user.user_email != null) ? this.user.user_email : ""
        this.user_phone = (this.user.user_phone != null) ? this.user.user_phone : ""

        this.shipment_content = (this.content != null) ? this.content : ""

        this.origin_full_name = (this.origin_direction.full_name != null) ? this.origin_direction.full_name : `${this.user_first_name} ${this.user_last_name}`
        this.origin_phone = (this.origin_direction.phone != null) ? this.origin_direction.phone : this.user_phone
        this.origin_email = (this.origin_direction.email != null) ? this.origin_direction.email : this.user_email
        this.origin_direction_1 = (this.origin_direction.direction_1 != null) ? this.origin_direction.direction_1 : ""
        this.origin_direction_2 = (this.origin_direction.direction_2 != null) ? this.origin_direction.direction_2 : ""
        this.origin_neighborhood = (this.origin_direction.neighborhood != null) ? this.origin_direction.neighborhood : ""

        this.destination_full_name = (this.destination_direction.full_name != null) ? this.destination_direction.full_name : ""
        this.destination_phone = (this.destination_direction.phone != null) ? this.destination_direction.phone : ""
        this.destination_email = (this.destination_direction.email != null) ? this.destination_direction.email : ""
        this.destination_direction_1 = (this.destination_direction.direction_1 != null) ? this.destination_direction.direction_1 : ""
        this.destination_direction_2 = (this.destination_direction.direction_2 != null) ? this.destination_direction.direction_2 : ""
        this.destination_neighborhood = (this.destination_direction.neighborhood != null) ? this.destination_direction.neighborhood : ""

    },
    data(){
        return{

            
        }
    },
    computed: {
        ...mapState(['shiphuk_shipment_id','wa_number','shipment_id','current_step','user','destination_direction','origin_direction','origin_direction_id','destination_direction_id','content'])
    },
    methods:{
        ...mapActions(['setCurrentStep','setdirectionData','updateShipment','injectShipmentData']),


        async nextStep(){


            const user = {
                user_first_name:this.user_first_name,
                user_last_name:this.user_last_name,
                user_email:this.user_email,
                user_phone:this.user_phone,
                content:this.shipment_content,
            }

            const user_data = {};

            for (const clave in user) {
                if (user[clave] !== null && user[clave] !== undefined && user[clave] !== "") {
                    user_data[clave] = user[clave];
                }
            }

            await this.updateShipment(user_data)

            const origin_direction = {
                full_name:this.origin_full_name,
                phone:this.origin_phone,
                email:this.origin_email,
                direction_1:this.origin_direction_1,
                direction_2:this.origin_direction_2,
                neighborhood:this.origin_neighborhood,
            }

            const origin_direction_data = {
                type:'origin'
            };

            
            for (const clave in origin_direction) {
                if (origin_direction[clave] !== null && origin_direction[clave] !== undefined && origin_direction[clave] !== "") {
                    origin_direction_data[clave] = origin_direction[clave];
                }
            }


            await  this.setdirectionData(origin_direction_data)

            const destination_direction = {
                full_name:this.destination_full_name,
                phone:this.destination_phone,
                email:this.destination_email,
                direction_1:this.destination_direction_1,
                direction_2:this.destination_direction_2,
                neighborhood:this.destination_neighborhood,
            }

            const destination_direction_data = {
                type:'destination'
            };

            
            for (const clave in destination_direction) {
                if (destination_direction[clave] !== null && destination_direction[clave] !== undefined && destination_direction[clave] !== "") {
                    destination_direction_data[clave] = destination_direction[clave];
                }
            }


            await this.setdirectionData(destination_direction_data)

            const shiphuk_rate_id =  this.$route.query.shiphuk_rate_id;
           
            
            this.injectShipmentData({
                shiphuk_shipment_id:this.shiphuk_shipment_id,
                shiphuk_rate_id: shiphuk_rate_id
            }).then(()=>{
                const payment_url = `https://ventapublico.enviaya.com.mx/payment_public/${this.shiphuk_shipment_id}`

                window.location.href = payment_url
            })

            


        },
      
    },
    components: {

        
        
    },

}

</script>

<style scoped>





#footer-actions button{
    width: 100%;
}

</style>