<template>
  <div>

    <header>
      <header-mostrador></header-mostrador>
    </header>
    <!-- El componente router-view muestra el contenido de la ruta actual -->
    <router-view></router-view>
    
    
    <footer>
      <!-- Pie de página -->
    </footer>
  </div>
</template>

<script>


import HeaderMostrador from './components/layout/HeaderMostrador.vue'

export default {
  name: 'App',
  components:{
    HeaderMostrador
  }

}
</script>