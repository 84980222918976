<template>
    <div class="wrapper">

    
    <div class="block">
        <h1 class="title has-text-centered is-size-5-mobile	">Especifica los <strong>datos de tu envío</strong></h1>
        <div class="field">
        <label class="label">Origen</label>
        <div class="control">
        <cp-selector
            id="origin"
            placeholder="Introduce un Código Postal"
            :items="originSuggestions"
            :itemProjection="directionProjection"
            @onInput="onInputOrigin"
            @selectItem="setOriginAddress"
            :defaultItem="origin_direction"           
            >
        </cp-selector>

          
        </div>
      </div>
      <div class="field">
        <label class="label">Destino</label>
        <div class="control">
          <cp-selector
            id="origin"
            placeholder="Introduce un Código Postal"
            :items="destinationSuggestions"
            :itemProjection="directionProjection"
            @onInput="onInputDestination"
            @selectItem="setDestinationAddress"
            :defaultItem="destination_direction"                   
            >
          </cp-selector>
          
        </div>
      </div>
    </div>
    <div class="block" id="footer-actions">
        <button class="button is-link" @click="redirectMB">Continuar</button>
    </div>
    </div>
   
</template>
    
<script>

import { mapState, mapActions } from 'vuex';

import 'vue3-simple-typeahead/dist/vue3-simple-typeahead.css'

import CpSelector from './../CpSelector.vue'

import axios from 'axios';

export default {
    name: 'OriginDestinationShort',
    data(){
        return{
            originSuggestions:[],
            destinationSuggestions:[],
            
        }
    },
    computed: {
        ...mapState(['wa_number','shipment_id','current_step','destination_direction','origin_direction','origin_direction_id','destination_direction_id'])
    },
    methods:{
        ...mapActions(['setdirectionData']),
        directionProjection(direction){

            if(typeof direction.value != "undefined"){
                return direction.value
            }
            return ""
        },
        onInputOrigin(item) {

            if(item.input.length >= 3){
            
                const apiUrl = 'https://ventapublico.enviaya.com.mx/shipping/address_short_search.json?query='+item.input+'&country=mx';

                axios .get(apiUrl).then((response) => {

                    this.originSuggestions = response.data

                }).catch((error) => {
                    console.error('Error al hacer la solicitud:', error);
                });
                
            }

        },
        onInputDestination(item) {

            if(item.input.length >= 3){

                const apiUrl = 'https://ventapublico.enviaya.com.mx/shipping/address_short_search.json?query='+item.input+'&country=mx';

                axios .get(apiUrl).then((response) => {

                    this.destinationSuggestions = response.data

                }).catch((error) => {
                    console.error('Error al hacer la solicitud:', error);
                });

            }

        },
        setOriginAddress(direction){

            const shiphuk_direction_id = (typeof direction.shiphuk_direction_id != 'undefined') ? direction.shiphuk_direction_id  : direction.id

            direction.shiphuk_direction_id = shiphuk_direction_id;
            
            if(typeof direction.value != 'undefined' && direction.value != ""){
                
                direction.type = 'origin';
               
                this.setdirectionData(direction)

            }
        },
        setDestinationAddress(direction){

            const shiphuk_direction_id = (typeof direction.shiphuk_direction_id != 'undefined') ? direction.shiphuk_direction_id  : direction.id

            direction.shiphuk_direction_id = shiphuk_direction_id;

            if(typeof direction.value != 'undefined' && direction.value != ""){
                
                direction.type = 'destination';
            
                this.setdirectionData(direction)

            }
        },
        redirectMB(){
            const url = "https://flows.messagebird.com/flows/invocations/webhooks/a2f88a9e-568b-42d4-8809-7f91f5356877"
            const data = {
                identifier:this.shipment_id.toString(),
                origin_direction: this.origin_direction,
                destination_direction: this.destination_direction,
            }


            axios.post(url,data).then(() => {

                
                // Genera la URL del enlace de WhatsApp
                const urlWA = `https://wa.me/${this.wa_number}`;

                // Redirige a la conversación de WhatsApp
                window.location.href = urlWA;
            })

        }
      
    },
    components: {
        CpSelector
    },

}

</script>

<style scoped>


.wrapper{
    min-height: calc(100vh - 188px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}


#footer-actions button{
    width: 100%;
}

</style>